import React,{useState,useEffect} from "react";
import { TextoRedim } from "../texto2/TextoRedim";
import { TextoEditado } from "../texto2/TextoEdit";

const RETURN_KEY = 13;
const ESCAPE_KEY = 27;

export default function EditableText({x,y,idSelec,id,seleccionado,texto,color,onTextClick}){
  
  const [text, setText] = useState("Ingrese texto");

  const [editando, setEditando] = useState(false);
  const [redim, setRedim] = useState(false);
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(40);
  const [tamaLetra,setTamaLetra] = useState(24)

  useEffect(() => {
    if (seleccionado!==idSelec && editando) {
      setEditando(false);
    } else if (seleccionado!==idSelec && redim) {
      setRedim(false);
    }
  }, [seleccionado, editando, redim]);



  function toggleEdit() {
    setEditando(!editando);
  }

  function handleFontSize(size){
    setTamaLetra(size)
  }

  function handleSize(newWidth, newHeight) {
    setWidth(newWidth);
    setHeight(newHeight);
  }

  function toggleTransforming() {
    setRedim(!redim);
    onTextClick(idSelec);
  }

  function handleEscapeKeys(e) {
    console.log("hola")
    if ((e.keyCode === RETURN_KEY && !e.shiftKey) || e.keyCode === ESCAPE_KEY) {
      toggleEdit(e);
    }
  }

  function handleTextChange(e) {
    setText(e.currentTarget.value);
  }

  if (editando) {
    return (
      <TextoEditado
        x={x} y={y} tamanio={tamaLetra}
        width={width} height={height} value={text}
        //onChange={handleTextChange} onKeyDown={handleEscapeKeys}
        onChange={()=>{}} onKeyDown={()=>{}}
        color={color}
      />
    );
  }

  return (
    <TextoRedim
      x={x} y={y} isSelected={redim}
      //onClick={toggleTransforming} onResize={handleSize} onFontSize={handleFontSize}
      text={texto} tamanio={tamaLetra}
      width={width} color={color}
      onClick={()=>{}} onResize={()=>{}} onFontSize={()=>{}}
    />
  );
}
