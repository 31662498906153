import React,{useState,useEffect} from 'react';
import {Box,LinearProgress,TextField,IconButton,Tooltip, Typography, Button,Card, FormControlLabel,
    Checkbox} from "@material-ui/core";
import MapaMarcadores from './MapaMarcadorSeccion';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import { green,red,orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import {toast } from 'react-toastify';
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {generaCoordenadaDinam} from '../funciones/Mapas/FormatoCoord'
import 'react-toastify/dist/ReactToastify.css';
import { Clear } from '@material-ui/icons';
import Brightness1Icon from '@material-ui/icons/Brightness1';

const useStyles = makeStyles((theme) => ({
    
    btnGuardar:{color: green[500],},
    btnCancelar:{color: red[500],},
    msnTxtError:{color: orange[500],fontWeight:550,},
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));
export default function MapaPanelMunicipio ({estadoId,munId,munSelect}){
    const classes = useStyles();    
    const source = axios.CancelToken.source();
    const history = useHistory();
    const usuid =localStorage.getItem('UsuId') ;       
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";   
    const [espera,setEspera] = useState(false)
    const [seccText,setSeccText] = useState('')
    const [poliLocal,setPoliLocal] = useState([])
    const [listPolEntrada,setListPolEntrada] = useState([])
    const [campoPoligono,setCampoPoligono] = useState([])
    const [centro,setCentro] = useState({lat: 19.493815, lng: -99.1137317});
    const [coordenada,setCoordenada] = useState([]);
    const [cambiaMarcador,setCambiaMarcador] = useState(true);    
    const [mensaje,setMensaje] = useState("");
    const [errorPoligono,setErrorPoligono] = useState("");
    const [msnError,setMsnError] = useState("");
    const [msnRegistro,setMsnRegistro] = useState("");    
    const [mostrarMensaje,setMostrarMensaje] = useState(false);
    const [cargando,setCargando] = useState(false);        
    const [listMapProm,setListMapProm] = useState([])
    const [listApoyos,setListApoyos] = useState([]) 
    const [listRecorrido,setListRecorrido] = useState([]) 
    const [checked, setChecked] = useState(false);
    const [checkApoyo, setCheckApoyo] = useState(false);
    const [checkRecorrido, setCheckRecorrido] = useState(false);
    const [checkEtiqSecc, setCheckEtiqSecc] = useState(false);
    useEffect(()=>{                                              
        
        if (checked&& seccText.length!==0) 
        {            
            llamaMapAvance(seccText) 
        }else{
            setListMapProm([])
        }        

    },[checked])

    useEffect(()=>{                                              
        
        if (checkApoyo&& seccText.length!==0) 
        {
            llamaMapApoyo(seccText)    
        }else{
            setListApoyos([])
        }        

    },[checkApoyo])

    useEffect(()=>{                                                      
        if (checkRecorrido&& seccText.length!==0) 
        {   llamaRecorrido(seccText)    
        }
        else
        {   setListRecorrido([])
        }        
    },[checkRecorrido])

    const llamaMapAvance = (auxSecc) => {        
        let data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:munId,
            Secc:auxSecc, Status:0,idDispositivo: auxDispV,
        });
        let url = "perfil-mapa-avance";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {
                return setListMapProm(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const llamaMapApoyo = (auxSecc) => {        
        let data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:munId,
            Secc:auxSecc, Status:0,idDispositivo: auxDispV,
        });
        let url = "estructura-mapa-avance";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {
                return setListApoyos(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const llamaRecorrido = (auxSecc) => {        
        let data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:munId,
            Secc:auxSecc, Status:0,idDispositivo: auxDispV,
        });
        let url = "recorrido-mapa-avance";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {
                return setListRecorrido(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const onChange = e =>
	{  
        let expNopermitida = new RegExp('[A-Za-z.%$@#_&-+()/:;!?,<>={}*|`]');        
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[0-9]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        {
            setSeccText( e.target.value )
            if (e.target.value===' '|| e.target.value==='') 
            {
                setPoliLocal([])
            }
        }		
	};

    const limpiaCheck=()=>{
        setChecked(false)
        setCheckApoyo(false)
        setCheckRecorrido(false)
        console.log("despues del cambio3");
    }

    const llamadaSecc=(seccion)=>{
        limpiaCheck()
        const getPoligonos = () => {
            const data = qs.stringify({
                usuario: usuid,       
                estado:process.env.REACT_APP_EDO,                
                seccion:seccion,
                idDispositivo: 'csdq21weddewde' 
            });
            const url = "lugar/v2/seccion/poligono";
    
            function respuesta(aux) {
                if (aux.length!==0 && aux[0]?.gps) 
                {   //console.log(aux);
                    setPoliLocal(aux);                       
                    const auxCoord=generaCoordenadaDinam(aux[0].gps[0].Lat, aux[0].gps[0].Lon)  
                  if (munSelect.Lat && munSelect.Lon && aux[0].gps.length<=1) {
                        setCentro({lat: munSelect.Lat, lng: munSelect.Lon})       
                    }
                    else
                    {
                        setCentro(auxCoord)   
                    }  
                    //setCentro( auxCoord)   
                    setMensaje("")              
                } 
                else
                {   if (aux[0].Id=== -1) 
                    {
                        console.log(aux);    
                        setMensaje("Sin poligonos")     
                        if (munSelect.Lat && munSelect.Lon) {
                            setCentro({lat: munSelect.Lat, lng: munSelect.Lon})       
                        }                               
                    }
                }    
            }    
            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
        }

        getPoligonos()
    }
    function getDatos(e){
        setListMapProm([])
        e.preventDefault();
        setPoliLocal([]);   
        llamadaSecc(seccText)        
    }

    const llamadaxEnter=(event)=>{
        if (event.keyCode === 13 && seccText.length>0) 
        {   event.preventDefault();
            setPoliLocal([]);   
            llamadaSecc(seccText)                        
        }
    }
    const handleChange = () => {
        setChecked(!checked)
    };

    const onChangeApoyo = () => {
        setCheckApoyo(!checkApoyo)
    };
    
    const onChangeRecorrido = () => {
        setCheckRecorrido(!checkRecorrido)
    };

    const cargarxEnterPol=(event)=>
    {  if (event.keyCode === 13 && campoPoligono.length>0) 
        {   event.preventDefault();     
            
            if (campoPoligono.indexOf("--") !== -1  || campoPoligono.indexOf('"') !== -1 || campoPoligono.indexOf('[') === -1 
                || campoPoligono.indexOf(']') === -1  ) 
            {
                setMsnError("Error cadena de poligonos incorrecta")
                setListPolEntrada([]) 
                setMsnRegistro("")
            } 
            else 
            {setMsnError(" ")
                setListPolEntrada(campoPoligono)  
                setMsnRegistro("")  
            }
            //console.log("entrando por boton enter2");
            
        }
    }

    const limpiarPolEnt=()=>{
        setCampoPoligono('')
        setListPolEntrada([])
        
    }

    const llamadaModificaSecc=(poligono)=>{
   
        const modPoligono = () => {
            setCargando(true)    
            const data = qs.stringify({
                seccion: seccText,         
                estado:estadoId,
                municipio:munId,           
                poligono:JSON.stringify(poligono),
                idDispositivo: 'csdq21weddewde' 
            });
            const url = "lugar-seccion-gps-ajuste";
    
            function respuesta(aux) 
            {   //console.log(aux);    
                if (aux.validacion==="OK") 
                {   setMostrarMensaje(false)
                    llamadaSecc(seccText)   
                    let mensaje2="Se grabo correctamente los poligonos"                                         
                    toast.success(mensaje2, 
                        {   position: toast.POSITION.BOTTOM_RIGHT, 
                            theme: "colored", autoClose: 10000, 
                            containerId:'mapaSeccionPol'+1
                        });                     
                } 
                else
                {
                    let mensaje2=aux.validacion                                         
                    toast.error(mensaje2, 
                        {   position: toast.POSITION.BOTTOM_RIGHT, 
                            theme: "colored", autoClose: 18000, 
                            containerId:'mapaSeccionPol'
                        }); 
                    if (mensaje2.length>=60) 
                    {
                        setErrorPoligono(mensaje2)        
                    }                    
                } 
                setCargando(false)             
            }    
            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
        }
        modPoligono()
    }

    const mostrarPolMensaje=(aux)=>{
        setMostrarMensaje(aux)
    }
    const agregarPolEnt=()=>{        
        let seccionEntrada=[]    
        if (listPolEntrada.length>0) 
        {   let poligonoRevision=JSON.parse(listPolEntrada);            
            console.log(listPolEntrada);                        
            console.log(poligonoRevision);
            poligonoRevision.forEach((poligono) => {                                         
                //let auxCoord=generaCoordenadaDinam(poligono[1], poligono[0]) 
                let aux1=poligono[1];            
                aux1=parseFloat(aux1)                  
                let aux2=poligono[0];                                                                      
                aux2=parseFloat(aux2)
                // console.log(aux1,aux2 );
                seccionEntrada.push({ lon :aux2 ,lat : aux1 })        
                //seccionEntrada.push(auxCoord)                                  
            })                     
            llamadaModificaSecc(seccionEntrada)                              
        }       
    }

    const onChangeEtiqSec = () => {
        setCheckEtiqSecc(!checkEtiqSecc)
    };

    const onChangePoligono = e =>
	{   let expNopermitida = new RegExp('[A-Za-z%$@#_&+()/:;!?<>=*|`]');        
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[0-9.,{}]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        {   
            let auxFiltro=e.target.value
            let auxCont=0,auxCont2=0
           
            while (auxFiltro.indexOf("]],[[")!== -1) 
            {                
                auxFiltro = auxFiltro.replace("]],[[", "],[")                 
                //console.log(`1er filtro vez  ${auxCont} `);
                auxCont=auxCont+1
            }
                        
            while (auxFiltro.indexOf("]]],[[[")!== -1) 
            {                
                auxFiltro=auxFiltro.replace("]]],[[[", "],[")                 
               // console.log(`2do filtro vez  ${auxCont2} `);
                auxCont2=auxCont2+1
            }            
            
            setCampoPoligono(auxFiltro )           
        }		
	};
    
 /*    const contMensaje =seccText.length>0 &&campoPoligono.length>0 && listPolEntrada.length!==0 && mostrarMensaje ?
                                    <Box display="flex" flexDirection={"row"} mb={1} justifyContent="center">
                                        <Typography component="h1" variant="h6" style={{fontSize:1.2+'rem'}} color="secondary">    
                                            ¿Esta seguro que desea actualizar los poligonos de la seccion {seccText}?
                                        </Typography>   
                                        <Button variant="outlined" size="small" style={{marginLeft:2+'rem'}} 
                                            disabled={cargando?true:false} className={classes.btnCancelar} onClick={e=>mostrarPolMensaje(false)}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button variant="outlined" size="small" style={{marginLeft:1.5+'rem'}} 
                                            disabled={cargando?true:false}  className={classes.btnGuardar} onClick={e=>agregarPolEnt()}
                                        >
                                            Grabar
                                        </Button>
                                    </Box> 
                                    :null     */
    const contMensajeError = errorPoligono.length!==0 ?
                                    <Card>
                                    <Box display="flex" flexDirection={"row"} mb={1} justifyContent="center">                                        
                                        <Typography component="h1" variant="h6" style={{fontSize:1.2+'rem'}} color="secondary">    
                                          {errorPoligono}
                                        </Typography>    
                                        <IconButton onClick={()=>{setErrorPoligono("")}} size="small">
                                            <Clear color="primary"/>
                                        </IconButton>                                                                   
                                    </Box> 
                                    </Card>
                                    :null                                       
    const mensajePoligono = mensaje.length > 0 ? <span style={{marginLeft:.5+'rem',marginTop:.3+'rem'}}> 
                                                    <Typography component="h1" variant="h5" >
                                                        {mensaje}
                                                    </Typography> 
                                                </span>: null
    const mensajeError = msnError.length > 0 ? <span style={{marginLeft:.5+'rem',marginTop:.3+'rem'}}> 
                                                 <Typography component="h1" variant="h5" className={classes.msnTxtError} >
                                                     {msnError}
                                                 </Typography> 
                                             </span>: null
 
    const contenido = (seccText.length>0 && poliLocal.length!==0 )||(campoPoligono.length>0 && listPolEntrada.length!==0 )  ? 
                                        (  <MapaMarcadores poligonos={poliLocal}  centro={centro} zoom={12.5}  
                                                cambiaMarcador={cambiaMarcador} setCambiaMarcador={setCambiaMarcador} 
                                                setCoordenada={setCoordenada} listPoligono={listPolEntrada} 
                                                listMapProm={listMapProm} listApoyos={listApoyos} listRecorrido={listRecorrido}
                                                checkEtiqSecc={checkEtiqSecc} setCheckEtiqSecc={setCheckEtiqSecc}
                                            />
                                        ):null;    

    const elementos = espera ? (<Box style={{marginTop:'2rem',marginLeft:'1em',width:'80%'}} > 
                                    <LinearProgress/> 
                                </Box>) : contenido;    

    const btnLimpiar =  <Button variant="outlined" style={{marginLeft:1+'rem'}} onClick={e=>limpiarPolEnt()}>
                            Limpiar
                        </Button>

/*     const btnModificar = seccText.length>0 &&campoPoligono.length>0 && listPolEntrada.length!==0 ?
                        (<Button variant="outlined" style={{marginLeft:1.5+'rem'}} onClick={e=>mostrarPolMensaje(true)} >
                            Cambiar Poligono
                        </Button>)
                        :(<Button variant="outlined" style={{marginLeft:1.5+'rem'}} disabled >
                            Cambiar Poligono
                        </Button>) */

    return (
    <div style={{display:'flex',marginTop:'.7rem'}}>  
    <Box display={"flex"} flexDirection="column">
        <Box display="flex">
            <Box display="flex"  alignItems="center" ml={2}>
                <FormControlLabel
                    disabled={espera || seccText.length===0}
                    control={<Checkbox color="primary" checked={checked} name={"checked"}/>}
                    label="Promovidos" size={'small'}
                    labelPlacement="end" onChange={handleChange}
                />
                <Brightness1Icon fontSize="small" style={{color: "#48bd4c"}} />
            </Box>
            
            <Box display="flex"  alignItems="center" ml={2}>
                <FormControlLabel
                    disabled={espera || seccText.length===0}
                    control={<Checkbox color="primary" checked={checkApoyo} name={"checkApoyo"}/>}
                    label="RG/RC" labelPlacement="end" size={'small'}
                    onChange={onChangeApoyo}
                /> 
                <Brightness1Icon fontSize="small" style={{color: "#aa1976"}} />
            </Box>
            <Box display="flex" alignItems="center" ml={3} >
                <FormControlLabel
                    disabled={espera || seccText.length===0}
                    control={<Checkbox color="primary" checked={checkRecorrido} name={"checkRecorrido"}/>}
                    label="Recorridos" labelPlacement="end"
                    size={'small'} onChange={onChangeRecorrido}
                />           
                <Brightness1Icon fontSize="small" style={{color: "#182ac9"}} />
            </Box>
            <Box display="flex" alignItems="center" ml={3} >
                <FormControlLabel
                    disabled={espera} label="Etiquetas" size={'small'}
                    control={<Checkbox color="primary" checked={checkEtiqSecc} name={"checkEtiqSecc"}/>}
                    labelPlacement="end" onChange={onChangeEtiqSec}
                />
           
                <Brightness1Icon fontSize="small" style={{color: "#0097A7"}} />
            </Box> 
            <Box display="flex" alignItems="center" ml={15} >
                <Typography>Sin Coordenadas</Typography>
                <Brightness1Icon fontSize="small" style={{color: "#fb8006"}} />
            </Box>
        </Box>           
        <Box display={"flex"} mb={1}>
            <TextField id="outlined-basic" label="Sección" variant="outlined" onKeyUp={(event)=>  llamadaxEnter(event)}
                inputProps= {{ maxlength: 4 }} size="small" value={seccText} onChange={onChange}
            /> 
            
            {seccText.length>0 ?
                <IconButton  onClick={getDatos}  style={{marginLeft:'.3em',marginTop:'.3em',width:'20px', height:'20px'}}>
                    <Tooltip title="Recuperar Sección">
                        <RefreshIcon color="primary"/>
                    </Tooltip>
                </IconButton>
            :   <IconButton  disabled style={{marginLeft:'.3em',marginTop:'.3em',width:'20px', height:'20px'}}>
                    <Tooltip title="Recuperar Sección">
                        <RefreshIcon color="primary"/>
                    </Tooltip>
                </IconButton>    
            }
            {poliLocal.length!==0?
              <Box display="flex" alignItems="center" ml={10} >
                <Typography>{poliLocal[0].Mun}</Typography>                
            </Box>
            :null}
           {/*  <Box ml={2}>
                <TextField id="outlined-basic" label="Poligonos" variant="outlined" onKeyUp={(event)=>  cargarxEnterPol(event)}
                   size="small" value={campoPoligono} onChange={onChangePoligono}
                />  
                {btnLimpiar}
                {btnModificar}
            </Box> */}
             
            {/*  coordenada.length!== 0 ? <span style={{marginLeft:.5+'rem',marginTop:.3+'rem'}}>
                <Typography component="h1" variant="h5">
                    {'lat '+coordenada.seccionLat} {' lng ' +coordenada.seccionLon}
                </Typography>        
                </span>
            :null */
            }
        </Box>
        {/* contMensaje */}   
        {contMensajeError}    
        {mensajePoligono}     
        {mensajeError}
        {elementos}
       
       
    </Box>        
    </div>
    );
}
