import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
//import clsx from 'clsx';
import {Box, TextField,FormControl, //Tooltip,
    Select,MenuItem,InputLabel,LinearProgress ,
    Card,IconButton,Tooltip,Typography, CardMedia
    } from '@material-ui/core' 
    import moment from 'moment';
import axios from 'axios';
import Progress from "./Progress";
//import qs from 'qs';
import CreateIcon from '@material-ui/icons/Create';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrarActualiza';
import { toast } from 'react-toastify'; 
import ContenedorImg from './ContenedorImg'
import { v4 as uuidv4 } from 'uuid';
import CheckIcon from '@material-ui/icons/Check';
import { obtenDispo } from '../funciones/IdDispositivo';
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display:'flex',
    flexDirection:'row'
  },
  formSelect: {
    width:'12em'
  },
  formDesc:{
    marginTop:'1.7em',
    width:'30em',
    height:'4em',
    display:'flex',
  },
  formImg:{
    marginTop:'.5em',
    width: 25+'rem',
    //height:25+'rem',
    //borderRadius: '.2rem'
  },
  cardDatos:{
    width:'100%',
    //height:30.5+'rem',
  },
  }));
const ModalNvoPerfilImg = ({modalAbierto,setModalAbierto ,titulo,actualizar,setActualizar,auxPerfImg ,nvoRegisPerfil}) => {
  let altuTamMax=825
  let anchoTamMax=1665
  let history = useHistory();  
  const classes = useStyles();
  const source = axios.CancelToken.source();  
  const stageRef = React.useRef();  
  const usuId =localStorage.getItem('UsuId') ;    
  const[nvoCliente,setNvoCliente]= useState({Tel:'',usuNick:'' });
  const [urlImg,setUrlImg] = useState("")  
  const [anchoTexto, setAnchoTexto] = useState(window.innerWidth<anchoTamMax ? 
      (window.innerWidth>=980 && window.innerWidth<1663 ?
        (window.innerWidth>=1400 && window.innerWidth<1663 ? 
          window.innerWidth-1300 : window.innerWidth-730 )
        : window.innerWidth/2
      ) 
    : window.innerWidth-1600)
  const [espera,setEspera]= useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
  const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
  const [plantillaBase,setPlantillaBase]= useState([])
  const [aviso, setAviso] = useState(false)
  const [texto1,setTexto1] = useState("")
  const [texto2,setTexto2] = useState("")
  const [texto3,setTexto3] = useState("")
  const [nombreArreglo,setNombreArreglo] = useState([])
  const [cardArreglo,setCardArreglo] = useState([])
  const [colorSelec,setColorSelec] = useState("#FFFFFF")
  const [colorSelec2,setColorSelec2] = useState("#FFFFFF")
  const [colorSelec3,setColorSelec3] = useState("#000000")
  const [progresoEnvio, setProgresoEnvio] = useState(0);
  const [auxLlave, setAuxLlave] = useState(0);
  const[cambioEfectivo,serCambioEfectivo] = useState(false)   
  React.useEffect(()=>{
    //console.log(auxPerfImg[0]);    
    //console.log(nvoRegisPerfil);
    //let auxNom="Juan Carlos Martinez"
    const auxNom=nvoRegisPerfil.length!==0 && nvoRegisPerfil.Nom.length>=3 ? obtenNombre(nvoRegisPerfil.Nom) :""
    const auxNom2=nvoRegisPerfil.length!==0 && nvoRegisPerfil.Nom.length>=3 ? nvoRegisPerfil.Nom :""
    const auxLlave=nvoRegisPerfil.length!==0 && nvoRegisPerfil.llave.length!==0 ?nvoRegisPerfil.llave :""
    const auxNombre=auxNom.length>20? auxNom.substring(0,20) :auxNom
    setTexto3(`Soy ${auxNombre} \n Y voy con...`)
    setAuxLlave(auxLlave)
    //setPlantillaBase(auxPerfImg[0])
    const filtroFondo= auxPerfImg.filter((plantilla)=>plantilla.Id === nvoRegisPerfil.PlantId)
    setUrlImg(filtroFondo[0].Fondo)
    //console.log(filtroFondo[0].Fondo);
    const arrNombre=generaCardContNombre(auxNom2)
    setNombreArreglo(arrNombre)
    let auxCard=[]
    for (let index = 0; index < arrNombre.length; index++) {
      auxCard.push({valor:true,id:index});      
    }
    setCardArreglo(auxCard)
    if(window.innerWidth<anchoTamMax)
    { if(window.innerWidth>=980 && window.innerWidth<1663)
      { if(window.innerWidth>=1400 && window.innerWidth<1663)
        { setAnchoTexto(window.innerWidth-1300)
        }
        else
        { setAnchoTexto(window.innerWidth-730)
        }
      }
      else
      { setAnchoTexto(window.innerWidth/2)
      }      
    }
    else
    { setAnchoTexto(window.innerWidth-1400)
    }
      
  },[])                            
    
  const obtenNombre=(nom)=>{
    const aux1=nom.indexOf(" ")
    return nom.substring(0,aux1+1)
  }

  async function llamadaNvoImg( auximg)  {       
    setEspera(true);
    let auxU=process.env.REACT_APP_LINK +`perfil/v2/perfil-file`;                               
    let token =localStorage.getItem('token20') ;               
    
    const usuOrg =localStorage.getItem('usuOrg') ;  
    const auxDispV =obtenDispo()                                  
    const bodyFormData = new FormData();
    bodyFormData.append('usuario',usuId)
    bodyFormData.append('llave',auxLlave)
    bodyFormData.append('mensaje','Prueba de datos')
    bodyFormData.append('color1','')
    bodyFormData.append('color2','')
    bodyFormData.append('color3','')
    bodyFormData.append('color4','')
    bodyFormData.append('idDispositivo',auxDispV)
    bodyFormData.append('multi-files',auximg)
    let config = {
      url:auxU ,
      method: 'POST',
      headers: { 
        "access-token": token,
        'size':auximg.size,
        "Content-Type": 'multipart/form-data',
        tipo:"SPACE",
        clase:'PERFIL',
        organismo:usuOrg,
      },
      data: bodyFormData,
      cancelToken: source.token,
      onUploadProgress: data => {
        let progreso= ((100 * data.loaded) / data.total).toFixed(2)
        //console.log(progreso);
        setProgresoEnvio(progreso)
      },
    };
    let aux ={};           
    await axios(config)
    .then(function (response) {              
      aux=  response.data;                                          
      console.log(aux)
      if (aux.length!==0) 
      { setProgresoEnvio(0)     
        setBtnDeshabilitado(true)                                                                                             
        let mensaje = "Imagen subida exitosamente";
        setTipoAdvertencia("success");
        setMensaje(mensaje);
        //setAviso(true);   
        //setPortadaEnviar()   
        setModalAbierto(false);      
        serCambioEfectivo(true)     
        setActualizar(!actualizar)                    
        toast.success(mensaje,{
            pauseOnFocusLoss: false,                    
            toastId: `exito-env${auxLlave}`
        })
        //setReiniciar(true)
      }                                            
    })
    .catch(function (error) {                  
      console.log(`Error: ${error}`);              
      if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
      {
        console.log('datos incorrectos' );  
      }else
      {
        if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
          ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
        {
          console.log('loguearse de nuevo');
          localStorage.clear();                      
          history.push("/");                              
        } 
      }
    });             
    setEspera(false);         
  };
  
  const mandaAlerta=(mensaje,tipoAdvert)=>
  {
    setMensaje(mensaje);
    setTipoAdvertencia(tipoAdvert)  
    setAviso(true);      
  }     
     
  function validaVariable(auxCampo,auxMensaje) 
  { let respuesta=false
    if (auxCampo.length>0 ) 
    {   respuesta=true                    
    }
    else
    {   mandaAlerta('Debe seleccionar un '+ auxMensaje,"warning") 
    }            
    return respuesta;
  }   
  
  function generarDescarga(){
    //event.preventDefault()    
    const dataURL = stageRef.current.toDataURL({ //transforma el objeto stage en 
      mimeType:"image/png",
      quality: 1,
      pixelRatio: 3
    })           
    let bytes = window.atob(dataURL.split(',')[1]) ;
    let mime = dataURL.split(',')[0].split(':')[1].split(';')[0];
    let max = bytes.length;
    let ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    //let date = new Date();
    //let aux =moment(date).format('YYMMDDHHmmss')
    let auxCode=uuidv4()       
    let newImageFileFromCanvas = new File([ia], auxCode+'.png', { type: mime });        
    //descargar2(newImageFileFromCanvas,aux)  
    llamadaNvoImg(newImageFileFromCanvas)
  }
  
  function guardarDatos(e)
  {  e.preventDefault()       
    if (  validaVariable(texto3,'Nombre'))
    {          
      setMensaje('');                
      setAviso(false);      
      generarDescarga()                   
    }   
  }   

  function cambiarColor3(e){
    setColorSelec3(e.target.value)
  }

  function onChangeTexto3(e){
    setTexto3(e.target.value)
  }

  function concatenaNom(nom,cad,valor){
    let nvoCad=nom
    if (valor) {
      nvoCad=nvoCad+cad+" "
    }
    return nvoCad
  }

  function recortaNombre(numero) {
    let nvaCad=""
    let nvoCards=[]    
    nombreArreglo.forEach((card)=>{
      if (card.id===numero) 
      { nvoCards.push({nom:card.nom,valor:(!card.valor) ,id:numero})      
        nvaCad=concatenaNom(nvaCad,card.nom,(!card.valor))
      }
      else
      { nvoCards.push({nom:card.nom,valor: card.valor ,id:card.id })        
        nvaCad=concatenaNom(nvaCad,card.nom,card.valor)
      }      
    })
    
    setTexto3(nvaCad)
    setNombreArreglo(nvoCards)
  }

  function generaCardContNombre(nombreOrigin) {
    let cadena = []    
    let element =""
    let auxNom=""
    let contador=0
    for (let index = 0; index < nombreOrigin.length; index++) 
    { element= nombreOrigin.charAt(index);            
      if (element!== " " ) 
      { auxNom=auxNom+element
        if ( (index+1) === nombreOrigin.length) 
        { cadena.push({nom:auxNom,valor:contador===0 ?true:false,id:contador})
          auxNom=""  
          contador=contador+1
        }
      }
      else
      { cadena.push({nom:auxNom,valor:contador===0 ?true:false,id:contador})
        contador=contador+1
        auxNom=""
      }
    }
    return cadena
  }

  const cardNombre=(cadena)=>{    
    
   return <Box display={"flex"} flexDirection="row" >
     <Box display={"flex"} flexDirection="row" flexWrap={"wrap"} width="22.7rem">
     <Box style={{marginTop:'.2em',marginRight:.3+'rem' }}>
      <input type="color" name="color" onChange={cambiarColor3} id='cambiarColor3' hidden/>
      <label htmlFor='cambiarColor3'>
        <Tooltip title={`Editar color del Nombre`}>
          <IconButton component="span" size="small">
            <CardMedia title="icono-colores" style={{width:25+'px',height:25+'px'}}
              image="CIRCULO DE PUNTOS20221222.svg" />            
          </IconButton>
        </Tooltip>
      </label>
    </Box>
    {cadena.map((elemento,index)=>{
      let auxColor=elemento.valor ? "primary":""
      return( 
      <Card onClick={()=>recortaNombre( elemento.id)} key={index}
        style={{marginRight:.5+'rem',paddingLeft:.3+'rem',paddingRight:.3+'rem',marginTop:.3+'rem',marginBottom:.4+'rem'}}        
      >
        <Box display={"flex"} flexDirection="row" >
        {elemento.valor ? <CheckIcon size="small" style={{paddingBottom:.1+'rem',paddingTop:.1+'rem'}} color={auxColor} />:null}
        <Typography color={auxColor}> {elemento.nom} </Typography>
        </Box>
      </Card>
      )
    })}
    
    </Box>
    
  </Box>  
  }

  const auxCards=nombreArreglo.length !== 0 ?cardNombre(nombreArreglo):null
  function generarInputs(){
    return(
    <Box display="flex" flexDirection="column" style={{ marginTop:'.5em'}} ml={2} >
      {auxCards}
    </Box>
    )
  }       

  const barraCarga=()=>{
   return( <Box style={{width:'100%', display:'flex', flexDirection:'row', marginTop:'.5em'}}>
      {progresoEnvio===100 ?
        <Typography variant="inherit" className={classes.fuenteTamanioArchivo} 
          style={{paddingLeft:'.5em',fontSize:'15px', paddingRight:'.5em'}}
        >
          Espere Por favor...
        </Typography>
      : null}
      {progresoEnvio>0 ? (
        <Progress progress={progresoEnvio}/>)
      :null}
  </Box>)
  }
  const DatosGenerales=(
    <div>                                    
      {urlImg.length!==0 ?
        <Box mt={1} ml={3} mr={3}>
        <ContenedorImg color1={colorSelec} color2={colorSelec2} color3={colorSelec3} 
          stageRef={stageRef} url={urlImg}  texto1={texto1} texto2={texto2} 
          texto3={texto3} onChangeTexto3={onChangeTexto3}
        />
        </Box>
      : null
      }
    </div>
  )       
  const guardarSeccionSelect=(e)=>{    
    if (e!== null && e!== undefined) 
    { //setMuniSelect(e)       
        setNvoCliente({
            ...nvoCliente,
            Seccion :e.target.value 
        })      
        setMensaje('');                
        setAviso(false); 
    }else
    { setNvoCliente({
        ...nvoCliente,
        Seccion : ""  
    })           
    }        
  }  
  
  const cerrarBorrar = () => {    
    
    setAviso(false);
    setModalAbierto(false);
    if (cambioEfectivo) 
    {   setActualizar(!actualizar)  
                                       
    }
};

  return (
  <>           
  <Modal
    titulo={titulo} modalAbierto={modalAbierto} mensaje={mensaje} 
    setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
    tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'md'} 
    btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
    cargando={espera}           cerrarBorrar={cerrarBorrar}  
  >
    
    {DatosGenerales}
    {/* urlImg!=="" && usuId===1 && prbId&& prbId===1? <img src={urlImg} />:null */}
    { /* espera ?
      <Box mt={3} mb={2} >
        {barraCarga()}
      </Box> 
    : generarInputs()  */ }

  </Modal>         
  </>
  )
}

export default ModalNvoPerfilImg