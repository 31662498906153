import React,{useState} from 'react'
import {TextField, Box, LinearProgress, FormControl, InputLabel, 
    Select, MenuItem, Tooltip} from '@material-ui/core' 
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrarActualiza';
import moment from 'moment'
import {llamaApiCargaToken,ErrorGeneral,llamadaApiCarga, llamadaApiToken } from '../funciones/ClienteAxios';
import { toast } from 'react-toastify'; 
import { obtenDispo } from '../funciones/IdDispositivo';
const ModalEditPerfil = ({modalAbierto,setModalAbierto ,titulo,perfil,listPerfil}) => {
    let history = useHistory();    
    const source = axios.CancelToken.source();  
    const usuID = localStorage.getItem('UsuId');
    const auxDispV =obtenDispo() 
    const fechaMax = moment(new Date()).subtract(18,'years')    
    const[nvoCliente,setNvoCliente]= useState({Llave:0,Nombre:'', FNacimien:fechaMax, Email:'' ,calle:'',
                                                Tel:'',ine:"",Municipio:"",Seccion:'',plantilla:0, apPat:"", apMat:"",
                                            });      
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] =  useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)
    const [aviso, setAviso] = useState(false);    
    const{ Llave, Nombre,FNacimien,Seccion,Tel,Municipio,apPat, apMat,}=nvoCliente            
    const[arrError,setArrError] = useState([false,false,false,false,false,false])   
    const[auxListSecc,setAuxListSecc]= useState([]);  
    const[listMun,setListMun]= useState([]);  
    const[listSecc,setListSecc]= useState([]);  
    
    React.useEffect(()=>{                      
        //let filtrado=listMun.filter((elemento)=>elemento.Id===perfil.MunId)         
        //let seccFilt=listSecc.filter((auxSeccion)=>auxSeccion.MuniId===perfil.MunId)              
        //setAuxListSecc(seccFilt)            
       /*  setNvoCliente({...nvoCliente,
            Llave:perfil.Id,
            Nombre:perfil.Nombre,
            Tel:perfil.Tel,                
            Municipio:perfil.MunId,// filtrado[0],            
            FNacimien: perfil.Nacimiento,
            Email:perfil.Email,
            Seccion:perfil.SeccId
        }) */
        getListSecc(perfil.MunId)
        getListMuni(perfil.EdoId,1)
    },[])

    
    function getListMuni(auxEdo,tipo)  {            
        const url= `lugar-municipio`;                            
        const data = qs.stringify({                
            usuario:usuID,              
            estado:auxEdo,                                                
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {   setListMun(aux)    
                if (tipo===1) {
                    const filtrado=aux.filter((elemento)=>elemento.Id===perfil.MunId)                          
                    setNvoCliente({...nvoCliente,Municipio:filtrado[0],
                        Llave:perfil.Id,
                        Nombre:perfil.Nombre,
                        apPat:perfil.Paterno,
                        apMat:perfil.Materno,
                        Tel:perfil.Tel,                                            
                        FNacimien: perfil.Nacimiento,
                        Email:perfil.Email,
                        Seccion:perfil.SeccId
                    })                                                        
                }
                
            }  
            else{
                setListMun([])
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    
    const getListSecc = (auxMuni) => {
        const data = qs.stringify({
            usuario: usuID, municipio: auxMuni, idDispositivo: auxDispV,
        });
        const url = "lugar-seccion";

        function respuestaSecc(auxiliar) {
            if (auxiliar[0].Id != -1) {                
                setAuxListSecc(auxiliar)                
            }
        }

        llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source);
    }
    
    const onChangeNom = e =>
	{   let expNopermitida = new RegExp('[0-9$.@#_&-+()/:;!?,<>=%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({...nvoCliente,[e.target.name] : e.target.value})        
            setBtnDeshabilitado(false)       
        }		
	}; 
    
    const onChange = e =>
	{   
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value//.toUpperCase()  
            })  
            if (e.target.value.length>=7) {
                setBtnDeshabilitado(false)                     
            }            
            else{
                setBtnDeshabilitado(true)                     
            }          
        }		
	};  
    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
            if (e.target.value.length===10) {
                setBtnDeshabilitado(false)                     
            }            
            else{
                setBtnDeshabilitado(true)                     
            }
        }		    
	};            

  /*   const verifiNombre = () => {                   
        let data = qs.stringify({
            cadena:Nombre.trim(),    
            idDispositivo: auxDispV,
        });
        let url = "perfil-verifica-cadena";
        function respuestaNombre(auxiliar){        
            const isEmpty = Object.keys(auxiliar).length === 0                        
            let auxArr=arrError
            if (isEmpty || auxiliar.coincidencia===0 ||  auxiliar.coincidencia==="0"   ) 
            {                 
               // console.log(auxiliar);   
                auxArr[3]=false 
                setArrError(auxArr)
                //setAviso(false)   
                const auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')  
                
                if (perfil.MunId!==Municipio.Id||perfil.Nombre!==Nombre.trim()) 
                {
                    validaDuplicado(auxFechaNacimient)              
                }
                else{
                    llamadaNvoPerfil()     
                }
                    
            } 
            else 
            {   const mensaje = "Nombre invalido, ingrese un Nombre diferente.";
                toast.warning(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `nvo-perfil${5}`
                })              
                mandaAlerta(mensaje, "warning");              
                auxArr[3]=true                                
                setArrError(auxArr)
                setBtnDeshabilitado(false)                            
            }
             
        }
        llamadaApiCarga(data, url, respuestaNombre,ErrorGeneral,setEspera,history);
    };
 */
    

    /* const validaDuplicado = (fecha) => {              
        const data = qs.stringify({
            nombre:Nombre.trim(),
            nacimiento:fecha+"T00:00:00",
            municipio:Municipio.Id,
            idDispositivo: auxDispV,
        });
        const url = "perfil-verifica-duplicado";
          function respuestaDuplicado(auxiliar){        
        const isEmpty = Object.keys(auxiliar).length === 0
        let auxArr=arrError
        if (isEmpty || auxiliar.rechazo===0 ||  auxiliar.rechazo==="0"   ) 
        {   //             
            auxArr[3]=true     
            setArrError(auxArr)                 
            llamadaNvoPerfil()              
        } 
        else 
        {   //let mensaje = "Nombre ya registrado, ingrese un Nombre diferente.";
            const mensaje = auxiliar.mensaje//"Nombre ya registrado, ingrese un Nombre diferente.";
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `nvo-perfil${6}`
            })                                    
            mandaAlerta(mensaje, "warning");
            auxArr=arrError
            auxArr[3]=true                    
            setArrError(auxArr) 
            setBtnDeshabilitado(false)               
        }
        llamadaApiCarga(data, url, respuestaDuplicado,ErrorGeneral,setEspera,history);
    };

  
    }     */
 
    const llamadaNvoPerfil=()=>{                     
                
        const data = qs.stringify({
            usuario:usuID,
            llave:Llave,
            telefono:Tel,
            nombre:Nombre.trim(), 
            paterno:apPat.trim(),
            materno:apMat.trim(),
            municipio:Municipio.Id,
            seccion:Seccion,
            nacimiento:obtnFch(),
            idDispositivo:auxDispV
        });
        function respuesta(aux){
            if (aux.length!==0 &&aux?.respuesta===1 ) 
            {                                        
                setBtnDeshabilitado(true)                                             
                let mensaje="Se edito correctamente el Perfil"
                setTipoAdvertencia("success")      
                setMensaje(mensaje);                
                setAviso(false);
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `edit-perfil${3}`
                })      
                
                let auxlistArray=listPerfil 
                const pos =auxlistArray.findIndex((pefl)=>pefl.Id===Llave) 
                const auxSeccion=auxListSecc.filter((elemento)=>elemento.Id===Seccion)   
                const arrEdit={...perfil,Tel:Tel,Nombre:Nombre, Paterno:apPat,
                    Materno:apMat,
                    MunNom:Municipio.Nom,
                    MunId : Municipio.Id, SeccNum:auxSeccion[0].Numero,SeccId:Seccion}
                auxlistArray.splice(pos, 1,arrEdit )  
                //console.log(auxlistArray);       
                setModalAbierto(false)
            }                            
        }
        const url = "perfil/v4/perfil/edit";
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    }    

    const guardarMuni=(e)=>{    
        setBtnDeshabilitado(true)   
        if (e!== null && e!== undefined) 
        {               
            //let seccFilt=listSecc.filter((auxSeccion)=>auxSeccion.MuniId===e.Id)
            //console.log(seccFilt); 
            getListSecc(e.Id)     
            //setAuxListSecc(seccFilt)    
            setNvoCliente({
                ...nvoCliente,
                Municipio : e  ,  
                Seccion :""  
            })      
            setMensaje('');                
            setAviso(false); 
        }else
        {   setNvoCliente({
                ...nvoCliente,
                Municipio : "" ,Seccion :""   
            })           
            setAuxListSecc([])  
        }        
    }

    const onChangeSeccion=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setNvoCliente({
                ...nvoCliente,
                Seccion :e.target.value 
            })      
            setBtnDeshabilitado(false)   
            setMensaje('');                
            setAviso(false); 
        }else
        { setNvoCliente({
            ...nvoCliente,
            Seccion : ""  
        })           
        }        
    }  
    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;                  
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                                
            mandaAlerta('No se lleno el campo '+descripcion,"warning")             
        }
        return(respuesta);
    }

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
      
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }
 
    function guardarDatos(e)
    {   e.preventDefault()                        
        if ( !verificaNulos(Municipio,"Municipio") && !verificaNulos(Seccion,"Sección") 
            && validaVariable(Nombre,'Nombre',2)&& validaVariable(apPat,'Apellido Paterno',2) 
            && validaVariable(apMat,'Apellido Materno',2) && validaVariable(Tel,'Telefono',10)
        )
        {           
            setMensaje('');                
            setAviso(false);                
            setBtnDeshabilitado(true)   
            //verifiNombre()                                   
            llamadaNvoPerfil()
        }                                  
    } 

    
    const RelojFNacimiento = ()=>
    { return <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>            
        <Tooltip title="Seleccionar Fecha Nacimiento">
          <Box  mr={1}  component="span" width={1/4}>           
            <DatePicker style={{width:8.3+'rem',marginBottom:"1rem"}}
              disableFuture  format="dd/MM/yyyy"
              views={["year", "month", "date"]}
              label="Fecha Nacimiento" size="small"
              openTo="year" cancelLabel={"Cancelar"}                  
              okLabel="Seleccionar"    
              minDate="1900-03-14" maxDate={fechaMax}
              value={FNacimien} onChange={guardarFNaci}            
            />                          
          </Box>    
        </Tooltip>                                             
        </MuiPickersUtilsProvider>
    }

    const guardarFNaci = auxfecha =>
    {   
        setNvoCliente({ ...nvoCliente,FNacimien : auxfecha  })
        setBtnDeshabilitado(false)   
    };

    const obtnFch=()=>{
        return `${moment( FNacimien).format('YYYY-MM-DD')}T00:00:00` 
        //return `${format(FNacimien,'YYYY-MM-DD')}T00:00:00` 
    } 

    const DatosGenerales=(
        <div>     
            <Box   pt={1} mb={1} display="flex" flexDirection={"row"}>
                {listMun.length!==0 ?
                <Autocomplete disabled
                    id="muni-select-nvoPerfil" size='small' style={{ width: 20.7+'rem' }}
                    onChange={(e,option)=>guardarMuni(option)}  value={Municipio}      
                    getOptionLabel={(option) => option.Nom} name="Municipio"             
                    noOptionsText={"Sin coincidencias"} options={listMun}
                    renderInput={(params) => (
                        <TextField {...params} size='small' 
                        label="Municipio" variant="outlined" />
                    )} 
                />
                :<LinearProgress/>}
                {auxListSecc.length!==0 ?                
                <Box display={"flex"} mb={1}>
                <FormControl size='small'  style={{marginLeft:1.6+'rem', minWidth:8.5+'rem'}} >
                    <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                    <Select  size='small' variant="outlined"
                        label="Sección" id="Seccion-select2" name="Seccion"
                        value={Seccion} onChange={onChangeSeccion}
                    >
                        {auxListSecc.map((secc, index) => {
                            if(secc.Id>0) {
                            return(
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>{secc.Numero }</em>
                            </MenuItem>
                            )}
                        } )}                    
                    </Select>
                    </FormControl>
                    </Box>
                :   null
                }
            </Box>      
            {RelojFNacimiento()}
            <br/>
            <TextField required id="Nombre-input" onChange={onChangeNom} style={{paddingBottom:.5+'rem',width:10+'rem',marginRight:".5rem"}}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
                //auxArrerror={arrError[3]} onBlur={()=>verifiNombre()}
            />                             

            <TextField required id="apPat-input" onChange={onChangeNom} style={{paddingBottom:.5+'rem',width:10+'rem',marginRight:".5rem"}}
                label="Apellido Paterno" value={apPat} name='apPat'size="small" autoComplete='off' // defaultValue="apPat"                
                //auxArrerror={arrError[3]} onBlur={()=>verifiNombre()}
            />

            <TextField required id="apMat-input" onChange={onChangeNom} style={{paddingBottom:.5+'rem',width:10+'rem'}}
                label="Apellido Materno" value={apMat} name='apMat'size="small" autoComplete='off' // defaultValue="apMat"                
                //auxArrerror={arrError[3]} onBlur={()=>verifiNombre()}
            />             
            <br/>                          
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{marginRight:1+'rem',width:8+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
                inputProps= {{ maxlength: 10 }} autoComplete='off'
            />   
        </div> 
    )     

    const cerrarBorrar = () => {              
        setAviso(false);
        setModalAbierto(false);        
    };

    return (
    <>           
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}     
        cerrarBorrar={cerrarBorrar}   
    >
        {DatosGenerales}
    </Modal>         
    </>
    )
}

export default ModalEditPerfil