import { Box, Card, Typography ,Paper} from '@material-ui/core'
import React from 'react'
import ModalInformativo from '../generales/ModalInformativoStd'

const ModalLimiteProm = ({setModalAbierto, modalAbierto, titulo, funcionCerrar,texto}) => {
   
    return (
    <ModalInformativo
        setModalAbierto={setModalAbierto} 
        modalAbierto={modalAbierto} titulo={titulo}
        tamanio='sm' colorFondo='#f5f5f5'// subtitulo={subtitulo}
        funcionCerrar={funcionCerrar}
    >
        <Box  style={{width:"25rem"}} >
        <Paper elevation={2}>
            <Card style={{marginBottom:.5+'rem'}}>
            <Typography variant="body2"   style={{marginLeft:0.5+'rem',marginRight:1+'rem',fontWeight:700}}>
                {texto}
            </Typography>                        
    
            </Card>
        </Paper>                        
        </Box>        
    </ModalInformativo>
    )
}

export default ModalLimiteProm
