import React, { useEffect, useState } from "react";
import {Button, Dialog, DialogActions,
        DialogContent, Typography} from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    alignItems: 'center',
    width: '20rem'
  },
  wrapper: {    
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
  buttonGuardar:{color: green[500],},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ModalAviso({titulo,children,modalAbierto,guardarDatos, tamanio, cerrarAviso,btnLimpiar}) {

  const classes = useStyles();    
  const [scroll, setScroll] = useState('paper');          
  const descriptionElementRef = React.useRef(null);
  
  useEffect(() => {
    if (modalAbierto) 
    { const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) 
      { descriptionElement.focus();
      }
    } 
  }, [modalAbierto]);

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
    );
  });

  return (
  <div>         
    <Dialog aria-labelledby="titulo" aria-describedby="descripcion"
      open={modalAbierto}  scroll={scroll}  fullWidth={false}
      maxWidth={tamanio}
    >
      <DialogTitle style={{color:process.env.REACT_APP_Fondo_Color}} id="titulo" align="center" >
        {titulo}        
      </DialogTitle>
            
      <DialogContent dividers={scroll === 'paper'} >             
        {children}        
      </DialogContent>
  
      <DialogActions>
        {btnLimpiar?
          <Button onClick={btnLimpiar}  color="primary"  variant="outlined">
            Limpiar
          </Button>
          :null
        }
        <Button onClick={cerrarAviso}  color="secondary"  variant="outlined">
          No
        </Button>
        <div className={classes.wrapper}>
          <Button onClick={guardarDatos}
            variant="outlined"   className={classes.buttonGuardar}
            //disabled={btnDeshabilitado ? true: cargando}            
          >
            Si
          </Button>          
        </div>                    
      </DialogActions>
    </Dialog>    
  </div>
  );
}