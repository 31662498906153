import React from 'react';
import {Card,Dialog,DialogActions,DialogContent
        ,DialogTitle,Paper,Typography} from '@material-ui/core';
import { makeStyles ,withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    //margin: theme.spacing(0),
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
     
}));

export default function ModalInformativo({titulo,subtitulo,children,modalAbierto,setModalAbierto,
  aviso, setAviso,tipoAdvertencia,mensaje,tamanio,funcionCerrar,nvoTitulo}) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');      
  

  const cerrarBorrar = () => {
    
    setOpen(false);
   // setAviso(false);
    setModalAbierto(false)
  };
  const handleClose = () => {
    setOpen(false);
    setModalAbierto(false)
  };
  const handleCloseSN = () => {
    setAviso(false);
    //setModalAbierto(false)
  };
  
  const classes = useStyles();
  //const [espera,setEspera]=React.useState(false);
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (modalAbierto) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }    
  }, [modalAbierto]);

  function tipoEdoProspecto(auxColorStd,etiqueta)
  {    
    let renglon=(
    
      <Card style={{color:auxColorStd==="#EBF1F8"? "black":'white', fontWeight:"bold",
         backgroundColor:auxColorStd ,maxWidth:'40%',marginTop:.3+'rem'}}
      >
        {etiqueta}
      </Card>
      )
    return renglon
  } 

  return (
  <div>         
    <Dialog
      open={modalAbierto} onClose={handleClose}
      scroll={scroll} aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
     maxWidth={tamanio}  fullWidth={tamanio==="md"?true:false} 
    >
                
      <DialogTitle style={{color:process.env.REACT_APP_Fondo_Color}} id="scroll-dialog-title" 
        align="center"    onClose={funcionCerrar ??handleClose}
      >
        {/*  */}
        {nvoTitulo&& (nvoTitulo.StatusId !== 1 || tamanio==="md")?
          tipoEdoProspecto(nvoTitulo.StatusColor,nvoTitulo.StatusNom)
          :titulo
        }
        
        {subtitulo && subtitulo.length!==0 ?
          <Typography color="textPrimary" style={{ fontWeight:600,fontSize:16,marginBottom:0,paddingBottom:0}}>
            {subtitulo}
          </Typography> 
        : null}
      </DialogTitle>
            
      <DialogContent dividers={scroll === 'paper'} >  
        <Paper>
        { aviso && tipoAdvertencia && mensaje.length!==0 ? 
          <Alert severity={tipoAdvertencia}  onClose={handleCloseSN}>{mensaje}</Alert>
          :<span></span>
        }
        {children}        
        </Paper>      
      </DialogContent>
    
    {/*   <DialogActions>
        <Button onClick={cerrarBorrar}  color="secondary" variant="outlined">
          Cerrar
        </Button>      
      </DialogActions> */}
      
    </Dialog>    
  </div>
  );
}