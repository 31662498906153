import React,{useEffect, useState} from 'react';
import {Box,LinearProgress, Typography,Checkbox,FormControlLabel} from "@material-ui/core";
import MapaMarcadores from './MapaConsultaMarcador';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {generaCoordenadaDinam} from '../funciones/Mapas/FormatoCoord'
export default function MapaPanelMunicipio ({estadoId,munId}){
    const history = useHistory();
    const source = axios.CancelToken.source();
    const usuid =localStorage.getItem('UsuId') ; 
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";         
    const [espera,setEspera] = useState(true)
    const [poliLocal,setPoliLocal] = useState([])
    const [centro,setCentro] = useState({lat: 19.493815, lng: -99.1137317});
    const [cambiaMarcador,setCambiaMarcador] = useState(true);    
    const [banderaError,setBanderaError] = useState("");     
    const [checkEtiqSecc, setCheckEtiqSecc] = useState(false);
    useEffect(()=>{        
               
        getPoligonos();                
        
        return ()=> {
            source.cancel();
        }            

    },[ ])   
   

    const getPoligonos = ( ) => {
        setPoliLocal([])
        setCentro([])
        const data = qs.stringify({
            Usuario: usuid, Edo:estadoId,
            Muni:munId, idDispositivo: auxDispV 
        });
        //const url = "lugar-seccion-poligono";
        const url="proyecto-edo-muni-seccion-diferencia"
        function respuesta(auxiliar) 
        {      // console.log(auxiliar);         
            if (auxiliar[0]?.Id !== -1 &&auxiliar[0].gps ) 
            {   
                setPoliLocal(auxiliar);   
                let auxContenido=false,auxCoord=[]
                auxiliar.forEach(element => {
                    if (!auxContenido&& element.gps &&element.gps[0].Lat!==0&&element.gps[0].Lon!==0) 
                    {
                        auxContenido=true
                        auxCoord=generaCoordenadaDinam(element.gps[0].Lat, element.gps[0].Lon) 
                    }
                });
                 
 
                setCentro(auxCoord)   
                if (auxContenido) 
                {   setBanderaError("")         
                }
                else
                {   setBanderaError("Municipio sin Coordenadas")     
                }
                
            }  
            else{
                setBanderaError("Municipio sin Coordenadas")     
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const elementos = espera ? (<Box style={{marginTop:'2rem',marginLeft:'1em',width:'20rem'}} > 
                                    <LinearProgress/> 
                                </Box>) :banderaError.length===0? 
                                    <MapaMarcadores poligonos={poliLocal}  centro={centro} 
                                        cambiaMarcador={cambiaMarcador} setCambiaMarcador={setCambiaMarcador}     zoom={12.9}                                 
                                        listMapProm={[]} listApoyos={[]} listRecorrido={[]}
                                        checkEtiqSecc={checkEtiqSecc} setCheckEtiqSecc={setCheckEtiqSecc}
                                    />
                            :<Typography>{banderaError}</Typography>
                            ;
    
    return (
    <div style={{display:'flex',justifyContent:'center',marginTop:'.5rem'}}>    
        <Box>            
            {elementos}
        </Box>        
    </div>
    );
}
