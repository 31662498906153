import React from 'react';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles'
import {generarTema} from './componentes/layout/Tema'
import AuthProvider  from './componentes/context/AuthContext';
import GeneralesProvider from './componentes/context/GeneralesContext';
import ClienteProvider from './componentes/context/ClienteContext';
import Contenedor from './componentes/layout/Contenedor'
import Login from './componentes/auth/Login'
import { ToastContainer } from 'react-toastify';
import PerfilInicio from './componentes/perfiles/PerfilInicio';
import Usuario from './componentes/usuario/UsuarioInicio'
import SugerenciaPublico from './componentes/SugerenciaPub';
import Apoyo from './componentes/Apoyo/Apoyo'
import Recorrido from './componentes/Recorrido'
import MapaSecciones from './componentes/mapa'
import Incidencia from './componentes/incidencias';
import Estructura from './componentes/estructura';
import Prep from './componentes/prep';
import Organizacion from './componentes/Organizacion';
import AvanceGeneral  from './componentes/AvGeneral';
import LimpiaCache from './componentes/LimpiaCache';
import Notificacion from './componentes/Notificacion';
import PublicoInicio from './componentes/publico';
import RepoSudoku from './componentes/repoSudoku';
import Rutas from './componentes/Rutas';
import 'react-toastify/dist/ReactToastify.css';
import 'mapbox-gl/dist/mapbox-gl.css';

function App() {
  const [colorTema,setColorTema] = React.useState(false);
  React.useEffect(()=>{
    const auxColor= localStorage.getItem('ColorTheme');
    if ( auxColor===undefined || auxColor===null) 
    {
      localStorage.setItem('ColorTheme',colorTema);
    //  console.log("sin el tema");
    }
    else
    {  //console.log("cargando el tema");
      //console.log(JSON.parse(auxColor));
      setColorTema(JSON.parse(auxColor))    
    }
  },[])

  return (
  <React.Fragment >
    <AuthProvider>
      <GeneralesProvider>
        <ClienteProvider>
        <Router>
          <ThemeProvider theme={generarTema(colorTema)}>
            <Contenedor colorTema={colorTema} setColorTema={setColorTema}>              
              <Switch>
                <Route exact path="/" component={Login}  />          
                <Route exact path="/lista-promovido" component={PerfilInicio}  />
                <Route exact path="/lista-publico" component={PublicoInicio}  />                 
                <Route exact path="/lista-Usuario" component={Usuario}  />                                              
                <Route exact path="/sugerencias" component={SugerenciaPublico}  />   
                <Route exact path="/apoyo" component={Apoyo}  />  
                <Route exact path="/recorrido" component={Recorrido}  />  
                <Route exact path="/mapa-secc" component={MapaSecciones}  />                       
                <Route exact path="/incidencia" component={Incidencia}  />
                <Route exact path="/estructura" component={Estructura}  />
                <Route exact path="/prep" component={Prep}  />                                       
                <Route exact path="/organizacion" component={Organizacion}  /> 
                <Route exact path="/avance-general" component={AvanceGeneral}  /> 
                <Route exact path="/limpiar-cache" component={LimpiaCache}  /> 
                <Route exact path="/notificacion" component={Notificacion}  /> 
                <Route exact path="/carga-total" component={RepoSudoku}  /> 
                <Route exact path="/rutas" component={Rutas}  /> 
               {/* */}
                <Route path="*" component={  Login } />
              </Switch>              
            </Contenedor>
          </ThemeProvider>
          <ToastContainer autoClose={5000} closeOnClick/>
        </Router>
        </ClienteProvider>
      </GeneralesProvider>
    </AuthProvider>
  </React.Fragment>
  );
}

export default App;