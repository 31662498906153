import React,{useEffect,useState,useContext } from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import { TextField,IconButton,Box,Tooltip,LinearProgress,FormControl,    
    Select,MenuItem,InputLabel, Typography,} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TablaRutas from './TablaRutas'
import NvoUsuario from './NuevoUsuario'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken, llamadaApiToken, ErrorGeneral } from '../funciones/ClienteAxios';
import TablaPermisoMun from './TablaPermisoMun';
import { toast } from 'react-toastify'; 
import SelectList from './SelectList';
import { obtenDispo } from '../funciones/IdDispositivo';

const useStyles = makeStyles({    
    buscador:{  width:'30em'  }, 
});

const Rutas = () => {
    const history = useHistory();
    const classes = useStyles();
    const source = axios.CancelToken.source();  
    const colorLetra=process.env.REACT_APP_Fondo_Color
    const usuID = localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')) : 0;
    const nvl= localStorage.getItem('Tipo') ? parseInt( localStorage.getItem('Tipo') ):0 ;       
    const usuTpEstr= localStorage.getItem('usuTpEstr') ? parseInt( localStorage.getItem('usuTpEstr') ):0 ;       
    const auxDispV =obtenDispo()
    const usu= localStorage.getItem('UsuarioNom');
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);    
   const [usrRegistro, setUsrRegistro] = useState(null); 
    const[usrBusq,setUsrBusq]= useState({nombre:'',edo:15,TipoUsu:"",muni:20, region:"", superior:""});
    const[listUsu,setListUsu]= useState([]);
    const[espera,setEspera]= useState(false);    
    const[esperaMun,setEsperaMun]= useState(false);
    const[actualizar,setActualizar]=useState(false);
    const [modalNvoUsrAbierto,setModalNvoUsrAbierto]= useState(false);     
    const[ mensaje, setTMensaje]= useState(false);     
    const [listTipoUsu, setListTipoUsu] = useState([]);     
    const[listMuni,setListMuni]= useState([]);
    const[listSuperior,setListSuperior]= useState([]);    
    const[ listMunUsr,setListMunUsr]= useState([]);
    const[ actualMunUsr,setActualMunUsr]= useState(false);
 
    const [listEdo,setListEdo] = useState([])
    const [listEstruc,setListEstruc] = useState([])
    const{nombre,edo,TipoUsu,muni, region, superior}=usrBusq    

    useEffect(() => {                                
                
        veriSesion();	

        return ()=> {
            source.cancel();
        }  
    }, [])

    useEffect(()=>{        
        if (nombre.length>=1 ) 
        {
            getListRuta(nombre)    
        }               
      
    },[actualizar])

    useEffect(()=>{        
        if (usrRegistro && usrRegistro.length!==0  ) 
        {
            
            getListDetalle(usrRegistro)    
        }
        
    },[usrRegistro,actualMunUsr])

    useEffect(() => {
      if (edo) {
        llamadaListMuni(edo)
      }
             
    }, [edo])

    useEffect(() => {
        if (TipoUsu) {
            getListSuperior(TipoUsu)
        }
               
      }, [TipoUsu])
    
    const veriSesion =  () => {            
        const auxValidacion=authUser()            
        if(!auxValidacion )
        {  
            guardarUsuario([])				
            guardarGenerales([])    
            localStorage.clear();            	             
            history.push("/")	                	
        }
        else
        {   
            if (usuario.length==0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                                             
            }                                                      
            guardarGenerales({...generales,Titulo:'Rutas'})   
            
            if (usuID !== null && usuID !== undefined 
                && (usuID === 1 || nvl > 2 || nvl === 1)) 
            {   getListTipo()                            
                llamadaListEdo()    
                getListEstrucTipo()        
                getListRuta()                                                                                  
            }
            else
            {   localStorage.clear();                            
                history.push("/");  
            }              
        }            
    }        

    const llamadaListEdo = () => {
        let data = qs.stringify({
            usuario: usuID,  idDispositivo: auxDispV,
        });
        let url = "lugar-estado";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {                
                return setListEdo(auxiliar)
            }else{
                setListEdo([])
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const getListDetalle = (auxRuta) => {               
        const data = qs.stringify({       
            Usuario:usuID,     
            Llave:auxRuta.Id,              
            idDispositivo:auxDispV,
        });   
        const url = "usuario/v1/estructura/ruta/det";
        function respuesta(aux) {
            if (aux.length !== 0) 
            {   setListMunUsr(aux)
            }       
        }    
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEsperaMun,history,source);
    } 
    
    const getListTipo = () => {               
        const data = qs.stringify({
            usuario:usuID  ,   
            idDispositivo:auxDispV,
        });   
        const url = "usuario-tipo-list";
        function respuesta(aux) {
            if (aux.length !== 0) 
            {                        
                let filtro=[]
                aux.forEach(element => {
                    if (element.Id!==1) {
                        filtro.push(element)
                    }
                });                                          
                setListTipoUsu(aux)   
            }       
        }    
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    } 

    const getListEstrucTipo = () => {               
        const data = qs.stringify({
            usuario:usuID  ,   
            idDispositivo:auxDispV,
        });   
        const url = "usuario/v2/estructura-tipo-list";
        function respuesta(aux) {
            if (aux.length !== 0) 
            {   let filtro=[]
                const tpFiltro=aux.filter((tpEstr)=> tpEstr.Id === usuTpEstr)
                if (usuTpEstr === 7) {
                    const tpFiltro=aux.filter((tpEstr)=> tpEstr.Id === 8)
                    filtro.push(tpFiltro[0])
                }
                else{
                    aux.forEach(element => {                        
                        if (element.Ord>tpFiltro[0].Ord ) 
                        {                            
                            filtro.push(element)
                        }
                    });
                }
                if (usuID===1) {
                    filtro=aux
                }
                setListEstruc(filtro)   
            }       
        }    
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    } 

    function llamadaListMuni(auxEdo)  {            
        const url= `lugar-municipio`;                            
        const data = qs.stringify({                
            usuario:usuID,              
            estado:auxEdo,                                                
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {   setListMuni(aux)
            }  
            else{
                setListMuni([])
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    function getListSuperior(tipo)  {            
        const url= `usuario/v2/estructura/superior`;                            
        const data = qs.stringify({                
            usuario:usuID,           
            tipo:tipo,               
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {                                   
                setListSuperior(aux)
            }                                                                      
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };
    
    const onChangeSlct=(e)=>{    
        e.preventDefault()
         if (e!== null && e!== undefined) 
        {      
            setUsrBusq({...usrBusq,[e.target.name]:e.target.value})   
        }
        else
        {   setUsrBusq({...usrBusq,[e.target.name]:""})   
       
        }        
    }

    const getListRuta = ( ) => {     
        setUsrRegistro(null)
        setListMunUsr([])          
        const data = qs.stringify({
            Usuario:usuID,                                                           
            idDispositivo:auxDispV,
        });   
        const url = "usuario/v1/estructura/ruta";
        function respuesta(aux) {
            if (aux[0].UsrId !== -1) 
            {  
              setTMensaje(false)         
              //aux.sort(function (a, b) { return a.Id - b.Id; });                                                    
              setListUsu(aux)
            }
            else
            {   setListUsu([])
                setTMensaje(true)             
            }       
        }    
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    } 
    
    const btnCargar =(e)=>{
        e.preventDefault()
        getListRuta()
        /* if (TipoUsu!=="" ) 
        {   if (nombre.length>=1) 
            { 
                //getListRuta(nombre)  
            }        
            else{
                const mensaje="Debe escribir un Nombre"
                toast.warning(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `toast-usuario${1}`
                })
            }        
        }
        else{
            const mensaje="Debe seleccionar Tipo"
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `toast-usuario${1}`
            })
        }        */       
    }

    const llamadaxEnter=(event)=>{
        event.preventDefault()
        if (event.keyCode === 13 ) 
        {   getListRuta(nombre)    
            /* if (nombre.length>=1) {
                if (TipoUsu!=="" ) 
                {        
                }
                else{
                    const mensaje="Debe seleccionar Tipo"
                    toast.warning(mensaje,{ pauseOnFocusLoss: false,                    
                        toastId: `toast-usuario${1}`
                    })
                }       
            }
            else{
                const mensaje="Debe escribir un nombre"
                toast.warning(mensaje,{ pauseOnFocusLoss: false,                    
                    toastId: `toast-usuario${1}`
                })
            } */
            
        }                
    }

    const onChange = e =>
    {   e.preventDefault()
        let expNopermitida = new RegExp('[:$!%({})/*|]');
        //let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z.0-9_-]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) //&& !expMenos.test(e.target.value) 
            &&!expMas.test(e.target.value)) ||e.target.value===' '||e.target.value==='') 
        {
            setUsrBusq({...usrBusq, [e.target.name] : e.target.value })
        }         
    };

    function abrirModalNvoUsr(e){        
        e.preventDefault() 
        setModalNvoUsrAbierto(true);    
    }

    function cambiosUsu(editado) {
        const nvo={...usrRegistro,TpEstrId: editado.tipoEstruc, TpEstrNom:editado.tpNom,
            SupId:editado.SuperId, SupNom:editado.SuperNom}
        setUsrRegistro(nvo)
       //const filtrado=listUsu.filter((usuario)=>usuario.UsrId!==nvo.UsrId)
        let arr=[]
        listUsu.forEach((usuario)=>{
            if (usuario.UsrId===nvo.UsrId) {
                arr.push(nvo)
            }
            else{
                arr.push(usuario)
            }
        })
        setListUsu(arr)
    }
    
    const menuAdmin=()=>{
    return(
    <Box display={"flex"} flexDirection="row">
       
        {/* checkTipo */}         
       
        <Box mt={2} ml={1}>        
            {/* <TextField className={classes.buscador} placeholder="Nombre" 
                name="nombre" onKeyUp={(event)=>  llamadaxEnter(event)}
                value={nombre} onChange={onChange} 
            /> */}
            <Tooltip title={`Actualizar lista usuarios`}>
                <IconButton aria-label="actualizar" onClick={(e) => btnCargar(e)} 
                    component="span" size="small" 
                >
                    <RefreshIcon style={{color:colorLetra  }} />
                </IconButton>
            </Tooltip>            
           {/*  <Tooltip title={`Crear Usuario`}>
                <IconButton aria-label="agregar nuevo" onClick={(e) => abrirModalNvoUsr(e)} 
                    component="span" size="small" 
                >
                    <AddCircleOutlineIcon style={{color:colorLetra }} />  
                </IconButton>
            </Tooltip> */}
 
        </Box>
       
    </Box>)
    }
 
    const recargar=()=>{
        setActualizar(!actualizar)
    }

    const checkTipo=  listEstruc.length > 0 ?                       
        <SelectList lista={listEstruc} value={TipoUsu} onChange={onChangeSlct} 
            etqVal={"Id"} etqId="Id" etqAlias={"Alias"} etiq={"Tipo"} 
            estilo={{marginLeft:1+'rem', minWidth:10+'rem'}} nameEtq={"TipoUsu"}
        />            
        :null   

 

    const modNvoUsr = modalNvoUsrAbierto ? 
                        ( <NvoUsuario modalAbierto={modalNvoUsrAbierto}  
                            setModalAbierto={setModalNvoUsrAbierto} titulo={'Nuevo Usuario'}                        
                            listTipoUsu={listTipoUsu} listEstruc={listEstruc}
                            recargar={recargar} listMuni={listMuni} 
                        />
                        ): null; 
    const barraCarga = espera ? <Box pt={3}> <LinearProgress/>  </Box>  :null   
    
    //valida que solo el usuario de tipo admin cree usuarios
    const menu =  menuAdmin() //auxValida ?: menuUsuLista()

 
    return (
    <Box mt={1}>
        
        {menu}
        <br/> 
        {mensaje?<Typography>Sin resultados</Typography>:null}
     
        {barraCarga }
        {modNvoUsr}
        <div style={{display:"flex", flexDirection:"row"}}>
        { listUsu.length>0 && !espera ? 
            <TablaRutas auxNombre={nombre} listUsu={listUsu} 
                auxActualizar={actualizar} setActualizar={setActualizar}
                listTipoUsu={listTipoUsu}  recargar={recargar}
                listMuni={listMuni}  
                usrRegistro={usrRegistro} setUsrRegistro={setUsrRegistro}
                listEstruc={listEstruc}
            />
        :null }
        {!esperaMun && usrRegistro && (nvl === 1 ||usuID===usrRegistro.Id || usuID === 37) && listMunUsr.length!==0    ?
            <TablaPermisoMun usrRegistro={usrRegistro} datos={listMunUsr} listEdo={listEdo}
                llamaApi={()=> setActualMunUsr(!actualMunUsr)} listEstruc={listEstruc} 
                listTipoUsu={listTipoUsu}  getEditUsu={cambiosUsu}
            />
        : ( esperaMun ? <Box mt={4} width={"20rem"}><LinearProgress/></Box> : null )
        }
        </div>
    </Box>
    )
}

export default Rutas
