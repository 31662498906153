import React, { useState,useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {  TableRow,Checkbox,Box   } from '@material-ui/core';
import { celda } from '../generales/TablaGeneral';
import {useHistory} from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken, } from "../funciones/ClienteAxios";
import {toast} from "react-toastify";
import qs from 'qs';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { obtenDispo } from '../funciones/IdDispositivo';
import {findNumberCad3} from '../funciones/FindNumber'
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        width: '15rem',
     
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: process.env.REACT_APP_COLOR
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function CheckPermisos({registro ,usrRegistro,llamaApi,listTodo}) {
    const classes = useStyles();
    const history = useHistory();
    const idusu = localStorage.getItem("UsuId");
    const auxDispV =obtenDispo()        
    const [selectedID, setSelectedID] = useState(null);
    const [operacion, setOperacion] = useState({Opera:0,status:false}); 
    const [espera,setEspera]=useState(false);
    const {Opera,status}=operacion;    
    const source = axios.CancelToken.source();
    
    useEffect(() => {
        if (listTodo.length!==0) 
        {
             
             const filtrado =listTodo.filter((elemnt)=>elemnt.id===registro.Id)
             if (filtrado.length!==0) 
             {
                 setOperacion({Opera:filtrado[0].id, status:filtrado[0].val===1?true:false})          
             }    
             else{
                setOperacion({Opera:registro.Id, status: registro.DetStatus === 1 ? true : false})          
             }
        }
        else
        {
            setOperacion({Opera:registro.Id, status: registro.Status === 1 ? true : false})          
        }
        //setOperacion({Opera:registro.Id, status: registro.DetStatus === 1 ? true : false})          
    }, [listTodo,registro])
    
    const seleccionado = (auxId, auxOrden) => {
        setSelectedID(auxId)       
    }

    const editar = (cambio) => {

        const editPermiso = () => {
          
            const data = qs.stringify({            
                idBase: usrRegistro.IdBase,   
                idUsuario:usrRegistro.UsrId,                 
                usuario:idusu,                
                detalle:JSON.stringify([{id:Opera,val:cambio?1:0}] ),
                idDispositivo: auxDispV
            });

            const url = 'usuario/v2/estructura-detalle-edit';

            function response(auxiliar) {
                let mensaje =""
                if (auxiliar.respuesta == 1) 
                {   mensaje = `Se modifico el registro ${registro.Nom}`    
                    llamaApi( )
                }                                
                toast.success(mensaje, {
                    toastId: `edit-user-per${Opera}`,
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            
            llamaApiCargaToken( data ,url, response, ErrorGeneral, setEspera, history, source);
        }

        editPermiso()
    }

    const handleChange = (event) => {        
        event.preventDefault();    
        setOperacion({...operacion,status :event.target.checked});
      //  editar(event.target.checked)
    };

    function Row(props) {
        const {row} = props;        
        return (        
        <TableRow //onClick={() => { seleccionado(row.Id, row) }}
            className={classes.tableRow} selected={selectedID === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >  
            {celda("center",{},
            
                espera ?
                    <Box style={{padding:.2+'rem'}}>
                        <CircularProgress size={28}/> 
                    </Box>
                :   <Checkbox disabled={findNumberCad3('|42|' ) ? false:true}
                        checked={status} // onChange={handleChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />                                                  
                ,
                1, classes.tableCell
            )}
            {celda("left", {fontSize:".7rem" }, `${row.SecNum} `, 3, classes.tableCell)}                    
        </TableRow>                 
        );
    }

    return ( <Row row={registro}/>  );
}