import React,{useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Card,CardContent,Box} from "@material-ui/core";
import NumberFormat from 'react-number-format';
const useStyles = makeStyles((theme) => ({
    card:{
        height:'4.1rem',
        width:130,
        // padding:'1.1 rem 0px 1.12rem',
        marginRight:'.5em',
    },
    card2:{
        height:'4.1rem',
        width:230,
       // padding:'1.1 rem 0px 1.12rem',
        marginRight:'.5em',
      },
    card3:{
        height:'4.1rem',
        width:180,
        // padding:'1.1 rem 0px 1.12rem',
        marginRight:'.5em',
    },
    titulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-.4rem',
        fontSize:'1rem',
        fontWeight:600
    },
    tituloColor:{

        //color:'white',
        textAlign:'center',
        marginTop:'-.6rem',
        fontSize:'1.2rem',
        fontWeight:600
    },
    subtitulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-1.4rem',
        fontSize:'.8rem',
    },
    subtituloCard:{
       // color:'#4a4a4a',
        textAlign:'center',
        marginTop:'.28rem',
        fontSize:'1.1rem',
    },
}));


export default function CardTotal({valor}){
    const classes = useStyles();
    const[total,setTotal]= useState([])
    const auxColor=process.env.REACT_APP_Fondo_Color
    React.useEffect(()=>{

        
      /*   console.log(valor); */

        const auxGralAv=valor.Avance
        const auxGralMeta=valor.Meta 
        const auxGralDto=valor.EdoAvc 
        const auxGralMun=valor.MunAvc 
        const auxGralSec=valor.SecAvc      
        const auxTotDto=valor.EdoTot 
        const auxTotMun=valor.MunTot 
        const auxTotSec=valor.SecTot        
        setTotal({//GrRG:auxGrRG,GrRC:auxGrRC,GrNo:auxGrNo,
            gralAv:auxGralAv,gralMeta:auxGralMeta,
            //UsrRG:auxUsrRG,UsrRC:auxUsrRC,UsrNo:auxUsrNo,
            gralMun:auxGralMun,gralSec:auxGralSec,
            totMun:auxTotMun,totSec:auxTotSec,
            gralDto:auxGralDto, totDto:auxTotDto
        })
    },[valor])  

    function generarCards(mensaje,datos,auxColor){
        return( 
        <CardContent>
            <Card className={classes.tituloColor} 
                style={{backgroundColor:auxColor,color:'white'}} 
            >                
                <NumberFormat value={datos} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>
        )
    }

    function generarCards2(mensaje,datGen,datTot,auxColor){
        return( 
        <CardContent>
            <Card className={classes.tituloColor} 
                style={{backgroundColor:auxColor,color:'white'}} 
            >                
                <NumberFormat value={datGen} displayType={'text'}  thousandSeparator={true}/>
                {' / '}
                <NumberFormat value={datTot} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>
        )
    }
  
    const totAv=total.length!==0 ?  (total.gralAv / total.gralMeta) * 100:0
    return (         
    <Box display="flex" flexDirection="row"  ml={1} >   
        {total.length!==0 ? 
        <Box display="flex" flexDirection="row"> 
            <Card className={classes.card2}>
                {generarCards2(<span>Avance <strong>{totAv.toFixed(2)}%</strong></span>
                ,total.gralAv, total.gralMeta,auxColor)}
            </Card>   
     {/*        <Card className={classes.card}>
                {generarCards2('Estados',total.gralDto, total.totDto,auxColor)}
            </Card>  */}                                             
            <Card className={classes.card}>
                {generarCards2('Municipio',total.gralMun, total.totMun,auxColor)}
            </Card>
            <Card className={classes.card3}>
                {generarCards2('Sección',total.gralSec, total.totSec,auxColor)}
            </Card>
          {/*    <Card className={classes.card}>
                {generarCards(dtoId? 'DTO RG':'General RG',total.GrRG,'#58b85c')}
            </Card>
            <Card className={classes.card}>
                {generarCards(dtoId? 'DTO RC':'General RC',total.GrRC ,'#4295f5')}
            </Card>
            <Card className={classes.card}>
                {generarCards(dtoId? 'DTO OBS':'General OBS', total.GrNo,auxColor)}
            </Card> */}     
            {/* <Card className={classes.card}>
                {generarCards('Usuario RG',total.UsrRG,'#58b85c')}
            </Card>
            <Card className={classes.card}>
                {generarCards('Usuario RC',total.UsrRC ,'#4295f5')}
            </Card>
            <Card className={classes.card}>
                {generarCards('Usuario OBS', total.UsrNo,auxColor)}
            </Card>      */}            
        </Box>   
        :null}
    </Box>
    );
}
 